<template>
  <div>
    <b-overlay :show="isFetching">
      <b-card>
        <b-row align-v="center">
          <b-col>
            <h6>
              {{ `${$t('fields.result')} (${total})` }}
            </h6>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-if="$allowPermission('agent:manage.whitelist')"
              variant="relief-primary"
              @click="toggleWhitelistModal(null)"
            >
              <feather-icon
                icon="PlusIcon"
              />
              {{ $t('buttons.add') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row
          align-h="center"
          class="my-2"
        >
          <b-col>
            <b-input-group>
              <b-form-input
                v-model="search"
                type="search"
                :placeholder="`${$t('fields.search')}...`"
              />
            </b-input-group>
          </b-col>
          <b-col
            v-if="isOwner"
            cols="3"
          >
            <master-select-input
              :value="selectedMaster"
              hide-label
              @update="onMasterIdChange"
            />
          </b-col>
          <b-col cols="3">
            <agent-select-input
              :value="selectedAgent"
              :master-id="selectedMaster"
              hide-label
              @update="onAgentIdChange"
            />
          </b-col>
        </b-row>
        <!-- Table -->
        <b-table
          :items="filteredList"
          :fields="fields"
          responsive
          show-empty
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(agent)="data">
            {{ data.value.name || '-' }}
          </template>
          <template #cell(creator)="data">
            {{ data.value.username || '-' }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(id)="data">
            <b-button-group
              v-if="$allowPermission('agent:manage.whitelist')"
              size="sm"
            >
              <button
                type="button"
                class="btn btn-danger btn-sm"
                @click="onDelete(data.value)"
              >
                {{ $t('buttons.remove') }}
              </button>
            </b-button-group>
          </template>
          <template #empty="">
            <p class="text-center text-muted">
              {{
                $t('messages.nothing_here')
              }}
            </p>
          </template>
          <template #cell(note)="data">
            <span v-if="data.value.length === 0">-</span>
            <b-badge v-else>
              {{ data.value }}
            </b-badge>
          </template>
        </b-table>
        <!-- pagination -->
        <b-row>
          <b-col md="4">
            <page-limit-select
              :value="limit"
              @update="onLimitChange"
            />
          </b-col>
          <b-col>
            <pagination-input
              :per-page="limit"
              :total="total"
              @update="(val) => currentPage = val"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <WhitelistFormModal
      ref="whitelistForm"
      no-btn
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Player List',
  },
  components: {
    WhitelistFormModal: () =>
      import('@/views/common-forms/WhitelistFormModal.vue'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      onSearchTimeout: null,
      fields: [
        '#',
        {
          key: 'agent',
          label: 'เอเย่นต์',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'creator',
          label: 'สร้างโดย',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'ip',
          label: 'IP',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'createdAt',
          label: this.$t('fields.createdAt'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '200px',
          },
          class: 'text-right',
        },
      ],
      isRemarkModalActive: false,
      remarkForm: {
        remark: '',
      },
      search: '',
      selectedMaster: '',
      selectedAgent: '',
      selectedNote: '',
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.whitelist.isFetchingWhitelists,
      whitelists: (state) => state.whitelist.whitelists,
    }),
    ...mapGetters(['isOwner']),
    filteredList() {
      return this.whitelists.items || []
    },
    pagination() {
      return this.whitelists.meta
    },
    limit() {
      return this.pagination?.itemsPerPage
    },
    total() {
      return this.pagination?.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchWhitelists', 'deleteWhitelist']),
    fetchData() {
      this.fetchWhitelists({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if (limit) {
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    toggleWhitelistModal() {
      this.$refs.whitelistForm.toggleModal()
    },
    onDelete(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteWhitelist(id)
          }
        })
    },
  },
}
</script>
